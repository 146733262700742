// import { Alert, Button, Grid, Typography } from '@mui/material';
import { Button, Grid, Typography } from '@mui/material';
import React, { Component } from 'react';
// import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import pageNotFoundImg from "../assets/Page not Found-image (1).svg";
import methodHubLogo from "../assets/mhit-icons/methodhublogo.svg"
class ErrorBoundary extends Component {
      constructor(props) {
            super(props);
            this.state = { hasError: false };
      }

      static getDerivedStateFromError(error) {
            return { hasError: true };
      }

      componentDidCatch(error, errorInfo) {
            console.error("Error loading chunk:", error, errorInfo);
      }

      retry = () => {
            this.setState({ hasError: false });
            window.location.reload();
      };


      render() {
            const flexClass = {
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center"
            }
            if (this.state.hasError) {
                  return <Grid sx={{ width: "100%", }}>
                        <Grid sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", margin: "3rem 0rem 0rem 4rem" }}>
                              <img src={methodHubLogo} alt='icon' />
                        </Grid>
                        <Grid sx={{ ...flexClass }}>
                              <img src={pageNotFoundImg} style={{ height: '30%', width: '30%' }} alt='icon' />
                              <Typography sx={{ fontSize: "30px", fontWeight: 900, color: "#1e384b" }}>OOPS !</Typography>
                              <Typography sx={{ fontSize: "20px", color: "#1e384b" }}>Something Went Wrong</Typography>
                              <Typography sx={{ color: "rgba(0, 0, 0, 0.6)" }}> We are facing an unexpected error. You may also</Typography>
                              <Typography sx={{ color: "rgba(0, 0, 0, 0.6)" }}>refresh  the page or try again later.</Typography>
                              <Button
                                    variant="contained"
                                    onClick={this.retry}
                                    sx={{ textTransform: "none", marginTop: "1rem", padding: "0.5rem 1rem" }}
                              >
                                    Try Again
                              </Button>

                        </Grid>

                  </Grid>
            }

            return this.props.children;
      }
}
export default ErrorBoundary;