// import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { configureStore } from "@reduxjs/toolkit";
import LoginReducer from "../pages/Login/LoginSlice"
import MenuItemSlice from "../components/Navbar/MenuItemSlice"


const store = configureStore({
    reducer: {
        Login: LoginReducer,
        ReportingDetail: MenuItemSlice,
    },

});

export default store;