import { Button, Checkbox, CircularProgress, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Typography } from "@mui/material";
import { StyledTextField } from "../../../components/StyledComponent/StyledComponent";
import "./SignIn.scss"
import { Suspense, useContext, useEffect, useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import axios from "axios";
import { getRequest, postRequest } from "../../../axios/axios";
import { toast } from "react-toastify";
import { formik, useFormik } from "formik";
import SignInSchema from "./SignInSchema";
import { reset, handleIsPasswordCreated, saveEmail, saveOtp, handleIsEmailValid, handleIsEmailVerifyed, handleForgetPassword, handleIsCallFromForgetPass, saveEmailValidate, updateRole } from "../LoginSlice"
import { useSelector } from "react-redux/es/hooks/useSelector";
import { useDispatch } from "react-redux";
import { save } from "../LoginSlice";
import { Link, useNavigate } from "react-router-dom";
import { useMsal } from '@azure/msal-react';
import { loginRequest, msalConfig } from "../../../config/authConfig";
import TickIcon from "../../../assets/tickIcon.svg";
import MHSupportIcon from "../../../assets/mhit-icons/MH-support-Logo.svg";

const Loader = () => {
  return (
    <CircularProgress
      size={50}
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        // marginTop: `${-50 / 2}px`,
        marginTop: '200px',
        marginLeft: `${-50 / 2}px`,
      }}
    />
  );
};

const SuspenseWrapper = ({ children }) => {
  return (
    <Suspense fallback={<Loader></Loader>}>
      {children}
    </Suspense>
  );
};


const SignIn = ({ handleSetPasswordComponent, handleUserEmail, isPasswordCreated }) => {
  window.localStorage.removeItem("fileClaimWithoutJob")

  const navigate = useNavigate()
  // Access token from MSAL cache
  const { instance } = useMsal();
  const accessToken = instance.getActiveAccount()?.accessToken;
  const dispatch = useDispatch();
  const [userEmail, setUserEmail] = useState("")
  const [userPass, setUserPass] = useState("")
  const [showPassword, setShowPassword] = useState(false);
  const [isDisabled, setIsDisables] = useState(true)
  const [isValidateCall, setIsValidateCall] = useState(true)
  const [onceClicked, setOnceClicked] = useState(false)
  const [alreadyExit, setAlreadyExit] = useState(false)
  const [isEmailVerifyied, setIsEmailVerifyed] = useState(false)
  const [loader, setLoader] = useState(false)
  const [isChecked, setIsChecked] = useState(false)


  const [openSignWithPass, setOpenSignWithPass] = useState(false)

  const handleOnChange = () => {
    setIsChecked((prev) => !prev)

  }


  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const getUserEmailFromRedux = useSelector((state) => {
    return state?.Login?.userDetail.email
  })
  const getUserEmailValidateFromRedux = useSelector((state) => {
    return state?.Login?.userDetail?.isEmailValidate
  })
  let organisation = localStorage.getItem("organisationType")

  useEffect(() => {
    if (organisation == "mh" &&false) {
      navigate('/home')
    }
    else if (organisation == "mh"||organisation == "mh") {
      navigate('/home')
    }
  }, [])

  let defaultValue = {
    email: "",
    password: "",
    isEmailVerified: false

  }

  const handleAccountinfoWithPass = async () => {
    if (Object.keys(formik.errors).length > 0) {
      return setOnceClicked(false)

    }
    if (formik.values.email.length === 0 || formik.values.password === 0) {
      return setOnceClicked(false)
    }
    const reqBody = {
      "email": formik.values.email,
      "password": formik.values.password,
      "provider": "mh"
    }

    const res = await postRequest("/api/users/login", reqBody, "sdLogin")
    if (res?.data?.isSuccess && res?.data?.statusCode == 200 && res?.data?.data === "Incorrect Password.") {
      toast.error("Incorrect Password")
      return setOnceClicked(false)


    }
    if (res?.data?.isSuccess && res?.data?.statusCode == 200 && res?.data?.data === "User not found") {
      toast.error("User not found")
      return setOnceClicked(false)

    }

    if (res?.data?.isSuccess && res?.data?.statusCode == 200) {
      toast.success("Login successfully")
      sessionStorage.setItem("EligibleFileClaimWithoutJob", 0)

      localStorage.removeItem("roleType")
      window.localStorage.setItem("loginToken", res?.data?.data?.token)
      window.localStorage.setItem("email", res?.data?.data?.user?.email)
      window.localStorage.setItem("servicerId", res?.data?.data?.user?.organisation?.servicer?.id)
      window.localStorage.setItem("roleType", res?.data?.data?.user?.roleType)
      window.localStorage.setItem("userName", res?.data?.data?.user?.userName)
      window.localStorage.setItem("organisationType", res?.data?.data?.user?.organisation?.organisationType)
      window.localStorage.setItem("organisationName", res?.data?.data?.user?.organisation?.organisationName)
      window.localStorage.setItem("keyCloakLoginToken", res?.data?.data?.keyCloakToken)
      window.localStorage.setItem("userId", res?.data?.data?.user?.userId)


      dispatch(updateRole(res?.data?.data?.user?.roleType))
      if (res?.data?.data?.user?.organisation) {
        if (res?.data?.data?.user?.organisation?.organisationType === "servicer") {
          navigate("/home", { state: { activeIndex: "2" } })
        }
        else if (res?.data?.data?.user?.organisation?.organisationType === "mh") {
          navigate("/home")
        }
        else {
          navigate("/AccessDenied")
        }
      }
      else {
        navigate("/AccessDenied")
      }
    }
    else {
      toast.error("Something Went Wrong")
      toast.error(res.data.error)
    }
  }


  const handleAccountinfo = async () => {


    if (userEmail.length === 0 || formik.values.email.length === 0) {
      return setLoader(false)

    }
    setOnceClicked(true)

    setLoader(true)
    if (isDisabled) {
      const reqBody = {
        "email": `${userEmail}`
      }
      const res = await postRequest("/api/email/validate", reqBody, "sdLogin")
      if (res.data.isSuccess) {

        setIsValidateCall(true)
        if (res.data.data.isEmailExist && !res.data.data.isPasswordExist) {
          dispatch(handleIsEmailValid(true))
          dispatch(saveEmailValidate(userEmail))
          setOnceClicked(false)

          if (handleSetPasswordComponent) {
            handleSetPasswordComponent(true)
            setOnceClicked(false)
          }
        }

        if (res.data.data.isEmailExist && res.data.data.isPasswordExist) {
          setIsDisables(false)
          setOnceClicked(false)
          formik.setFieldValue("isEmailVerified", true)
          setAlreadyExit(true)
          setIsEmailVerifyed(true)

          dispatch(saveEmailValidate(userEmail))
        }
        if (!res.data.data.isEmailExist && !res.data.data.isPasswordExist) {
          toast.error("User with this Email does not Exist")
          setOnceClicked(false)

        }
      }
      else {
        setIsValidateCall(true)
        setOnceClicked(false)
        setLoader(false)

        toast.error(res.data.error)
      }
    }
    if (!isDisabled) {
      handleAccountinfoWithPass()

    }

  }
  const formik = useFormik({
    enableReinitialize: true,

    initialValues: defaultValue,
    validationSchema: SignInSchema,
    onSubmit: handleAccountinfo

  });

  const handleEmailChange = (e) => {
    setLoader(false)
    formik.setFieldValue("email", e.target.value);
    dispatch(saveEmail(e.target.value))
    setUserEmail(e.target.value)

    if (handleUserEmail) {
      handleUserEmail(e.target.value)
    }
    setIsEmailVerifyed(false)
    if (!isDisabled) {
      setIsDisables(true)
    }
    setIsDisables(true)


  }
  const handleOnCLickOnForgetPassword = () => {
    dispatch(reset())
    dispatch(handleForgetPassword(true))
    dispatch(handleIsCallFromForgetPass(true))
  }

  const microsoftLogin = async () => {
    try {
      // Initiate the login process
      const response = await instance.loginPopup(loginRequest);
      let res;
      // Log the payload of the token request
      let obj = {
        "token": response.accessToken,
        "provider": "microsoft"
      }
      if (response) {
        res = await postRequest("/api/users/login", obj, "sdLogin")
      }
      if (res?.data?.isSuccess == true) {
        toast.success("Login successfully")
        sessionStorage.setItem("EligibleFileClaimWithoutJob", 0)
        localStorage.removeItem("roleType")
        window.localStorage.setItem("loginToken", res?.data?.data?.token)
        window.localStorage.setItem("email", res?.data?.data?.user?.email)
        window.localStorage.setItem("servicerId", res?.data?.data?.user?.organisation?.servicerId)
        window.localStorage.setItem("roleType", res?.data?.data?.user?.roleType)
        window.localStorage.setItem("userName", res?.data?.data?.user?.userName)
        window.localStorage.setItem("organisationType", res?.data?.data?.user?.organisation?.organisationType)
        window.localStorage.setItem("organisationName", res?.data?.data?.user?.organisation?.organisationName)
        window.localStorage.setItem("userId", res?.data?.data?.user?.userId)
        window.localStorage.setItem("keyCloakLoginToken", res?.data?.data?.keyCloakToken)
        dispatch(updateRole(res?.data?.data?.user?.roleType))

        if (res?.data?.data?.user?.organisation?.organisationType == "servicer") {
          navigate("/home", { state: { activeIndex: "2" } })
        }
        else if (res?.data?.data?.user?.organisation?.organisationType == "opw") {
          navigate("/home")
        }
        else {
          navigate("/AccessDenied")
        }
      }
    }
    catch (error) {
    }
  }

  return (
    <>
      <Grid className="main-sign">
        <form onSubmit={formik.handleSubmit}>

          <Grid container className="sign-in">
            <Grid item sx={{ display: "flex", flexDirection: "column" }} >
              <Grid className="mh-support-logo" item>
                <img src={MHSupportIcon} alt="icon" height="40px" />
              </Grid>
            </Grid>


            <Grid container sx={{ display: "flex", justifyContent: "center", flexDirection: "column", marginTop: "5px" }} spacing={1}>
              <Grid sx={{ display: "flex", alignItems: "center", marginLeft: "8px" }}>
                <Grid item xs={12} sm={12} >
                  <StyledTextField
                    size="small"
                    fullWidth
                    name="email"
                    label="Email Address"
                    onChange={(e) => handleEmailChange(e)}
                    value={formik.values.email}

                    onBlur={(e) => {
                      formik.handleBlur(e);
                      if (Object.keys(formik.errors).length === 0) {
                        handleAccountinfo();
                        setIsValidateCall(false)
                      } else {
                        handleAccountinfo();
                        setIsValidateCall(true)


                      }
                    }}


                    error={Boolean(
                      formik.touched.email && formik.errors.email
                    )}
                  />
                  {Boolean(
                    formik.touched.email && formik.errors.email
                  ) && (
                      <Typography variant="body2" color="error">
                        {formik.errors.email}
                      </Typography>
                    )}

                </Grid>
                {
                  loader &&
                  (isEmailVerifyied ? (
                    <Grid sx={{ marginLeft: "10px" }}>
                      <img src={TickIcon} alt="icon" />
                    </Grid>) : <CircularProgress size={30} sx={{ color: "green", marginLeft: "10px" }} />
                  )}

              </Grid>

              <Grid item sm={12} xs={12} sx={{ marginTop: "10px", marginBottom: "5px" }}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="password" sx={{
                    "&.MuiInputLabel-root": {
                      top: "-5px"
                    }
                  }}>{<span style={{ padding: 0, margin: 0, textAlign: "center", color: "#1A4277" }}>Password</span>}</InputLabel>

                  <OutlinedInput
                    sx={{
                      backgroundColor: "#F9F9F9",
                      border: "1px solid #F9F9F9",

                      "&.MuiOutlinedInput-root.Mui-disabled": {
                        border: "1px solid #F9F9F9"
                      },
                      "&.MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline ": {
                        border: "1px solid #F9F9F9"

                      },
                      '& .MuiInputLabel-root':{
                       color: "#1A4277"
                        },
                      "&.MuiOutlinedInput-root.MuiOutlinedInput-root:hover fieldset":{
                        border: "2px solid #1A4277 ",
                      },
   

  
                    }}
                    disabled={isDisabled}
                    size="small"
                    id="password"
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    onChange={(e) => {
                      formik.setFieldValue("password", e.target.value);
                      setUserPass(e.target.value)
                    }}
                    onBlur={formik.handleBlur}

                    label="Password"
                    error={Boolean(
                      formik.touched.password && formik.errors.password
                    )}
                  />
                  {Boolean(
                    formik.touched.password && formik.errors.password
                  ) && (
                      <Typography variant="body2" color="error">
                        {formik.errors.password}
                      </Typography>
                    )}
                </FormControl>
              </Grid>

              {/* {
                !openSignWithPass &&
                (
                  <Grid item sm={11} sx={{ marginTop: "10px" }}>

                    <FormControl fullWidth>
                      <InputLabel htmlFor="password" sx={{
                        "&.MuiInputLabel-root": {
                          top: "-5px"
                        }
                      }}>{<span style={{ padding: 0, margin: 0, textAlign: "center" }}>Password</span>}</InputLabel>

                      <OutlinedInput
                        size="small"
                        id="password"
                        name="password"
                        type={showPassword ? 'text' : 'password'}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        }
                        onChange={(e) => {
                          formik.setFieldValue("password", e.target.value);
                          setUserPass(e.target.value)
                        }}
                        onBlur={formik.handleBlur}
                        disabled={isDisabled}
                        label="Password"
                        error={Boolean(
                          formik.touched.password && formik.errors.password
                        )}
                      />
                      {Boolean(
                        formik.touched.password && formik.errors.password
                      ) && (
                          <Typography variant="body2" color="error">
                            {formik.errors.password}
                          </Typography>
                        )}
                    </FormControl>

                  </Grid>
                )

              } */}
              {/* {
                alreadyExit && (
                  <Grid item sx={{ display: "flex", justifyContent: "end", alignItems: "center" }} >
                    <Button
                      disableElevation
                      disableRipple
                      sx={{
                        color: " #02A0FC", textTransform: "none", cursor: "pointer", "&.MuiButton-root:hover": {
                          backgroundColor: "#FFF"
                        },
                        padding: 0, margin: 0
                      }}
                      onMouseEnter={(e) => e.target.style.textDecoration = "underline"}
                      onMouseLeave={(e) => e.target.style.textDecoration = "none"}
                      onClick={handleOnCLickOnForgetPassword}
                    >
                      Forgot Password?
                    </Button>
                  </Grid>
                )
              } */}

              {/* <Grid item sx={{ display: "flex", alignItems: "center" }}>
                <Checkbox
                  sx={{ padding: "9px 9px 9px 0px", color: "#757C80" }}
                  id="account.self-dealer"
                  name="account.isSelfServicingDealer"
                  defaultChecked
                  onChange={handleOnChange}

                />

                <Typography
                  variant="caption"
                  sx={{ marginTop: "5px", color: "rgba(0, 0, 0, 0.6)", textTransform: "none" }}
                >
                  I give consent to IT-Support MH to use my email to contact me for tasks related information.
                </Typography>
              </Grid> */}
            </Grid>







            <Grid container sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", marginTop: "0px" }} spacing={2}>
              {/* <Grid item >
                <Button variant="contained" type="submit" >Lets get Started ! (have Password)</Button>
              </Grid> */}





              <Grid item style={{ marginTop: "0.2rem" }}>
                <Button variant="contained" type="submit" className="main-login-button" sx={{'&.MuiButton-root:hover':{backgroundColor:"#1A4277"},backgroundColor:'#1A4277'}}
                  disabled={isChecked ? true : false || onceClicked}
                >Login</Button>
              </Grid>
              {/* <Grid item style={{ marginTop: "0.2rem" }}>
                <Button onClick={() => { microsoftLogin() }} variant="outlined" sx={{ textTransform: "none" }}></Button>
              </Grid> */}



            </Grid>

            {/* <Grid container sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", marginTop: "0px" }} spacing={2}>
              <Grid item>
                <Typography variant="caption" sx={{ marginTop: "5px", color: "rgba(0, 0, 0, 0.6)" }} >
                  Don't have an account yet ?

                  <Link
                    style={{ color: " #02A0FC", textDecoration: "none", cursor: "pointer", marginLeft: "5px" }}
                    onMouseEnter={(e) => e.target.style.textDecoration = "underline"}
                    onMouseLeave={(e) => e.target.style.textDecoration = "none"}
                  >
                    Create Account
                  </Link>
                </Typography>
              </Grid>


            </Grid> */}

          </Grid>

        </form>

      </Grid>

    </>
  )
}
export default SignIn;
