import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import AuthProvider from './utils/AuthProvider';
import App from './App';
import { MsalProvider } from '@azure/msal-react';
import msalInstance from './utils/AuthProvider';
import { Provider } from 'react-redux';
import store from './redux/store';

const root = ReactDOM.createRoot(document.getElementById('root'));
// if (process.env.REACT_APP_ENV === 'prod' || process.env.REACT_APP_ENV === 'uat') {
//   console.log = function () { };
// }
root.render(
  <Provider store={store}>
    <MsalProvider instance={msalInstance}>
      <App />
    </MsalProvider>
  </Provider>
  // <React.StrictMode>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
