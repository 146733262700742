import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import NotFound from '../pages/NotFound';
// import AccessDenied from '../pages/AccessDenied/AccessDenied';
import Login from '../pages/Login/Login';


const PrivateRoute = ({ normalComponent = <NotFound />, acessdenied = <NotFound />, notFound = "" }) => {
  // let fileClaimWithoutJob = window.localStorage?.getItem("fileClaimWithoutJob")

  // const navigate = useNavigate();
  // const [userRole, setUserRole] = useState(localStorage.getItem('roleType'));
  const [userRole] = useState(localStorage.getItem('organisationType'));

  const token = localStorage.getItem("loginToken")

  if (!token) {
    return <Login />
  }
  if (notFound) {
    return notFound
  }
  if (userRole === "mh") {
    return normalComponent;
  }
  else if (userRole === "servicer") {
    return normalComponent;
  }
  else {
    return acessdenied
  }
};

export default PrivateRoute;