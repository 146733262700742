import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isClaim: false,
    isDispatch: false,
    isNetwork: false,
    isSubMenu: true,
    isReportingRender: false,
    closeSubmenu: true,
    ReportNameId: "",
    isHistoryDataCall: false,
    isTableDataCall: false,
    showDownloadBookMark: false,
    showIsHistoryTableLoader: false,
    showIsTableLoader: false,
    ReportingObj: {},
    getMenuData: [],
    getFilterData: [],
    getHistoryTableData: [],
    getAddToBookMarkData: [],
    getClickedSubMenuEvent: [],
    getTableData: [],
    getSaveFilterSearch: [],
    getFilterToShowDefault: [],
    getFilterToShowRest: [],
    getSelectedCountryId: null
};

const MenuItemSlice = createSlice({
    name: "ReportingDetail",
    initialState,
    reducers: {
        saveReportingObj: (state, action) => {
            state.ReportingObj = action.payload;
        },
        saveIsDispatch: (state, action) => {
            state.isDispatch = action.payload
        },
        saveIsNetwork: (state, action) => {
            state.isNetwork = action.payload
        },
        saveIsClaim: (state, action) => {
            state.isClaim = action.payload
        },
        saveIsSubMenu: (state, action) => {
            state.isSubMenu = action.payload
        },
        saveIsMenuData: (state, action) => {
            state.getMenuData = action.payload
        },
        saveIsReportingRender: (state, action) => {
            state.isReportingRender = action.payload
        },
        saveIsCloseSubmenu: (state, action) => {
            state.closeSubmenu = action.payload
        },
        saveIsFilterData: (state, action) => {
            state.getFilterData = action.payload
        },
        saveIsHistoryTableData: (state, action) => {
            state.getHistoryTableData = action.payload
        },
        saveIsReportNameId: (state, action) => {
            state.ReportNameId = action.payload
        },
        saveIsTableData: (state, action) => {
            state.getTableData = action.payload
        },
        saveIsHistoryDataCall: (state, action) => {
            state.isHistoryDataCall = action.payload
        },
        saveIsTableDataCall: (state, action) => {
            state.isTableDataCall = action.payload
        },
        saveIsAddToBookMarkData: (state, action) => {
            state.getAddToBookMarkData = action.payload
        },
        saveIsClickedSubMenuEvent: (state, action) => {
            state.getClickedSubMenuEvent = action.payload
        },
        saveIsShowDownloadBookMark: (state, action) => {
            state.showDownloadBookMark = action.payload
        },
        saveIsHistoryTableLoader: (state, action) => {
            state.showIsHistoryTableLoader = action.payload
        },
        saveIsTableLoader: (state, action) => {
            state.showIsTableLoader = action.payload
        },
        saveIsFilterSearch: (state, action) => {
            state.getSaveFilterSearch = action.payload
        },
        saveIsFilterToShowDefault: (state, action) => {
            state.getFilterToShowDefault = action.payload
        },
        saveIsFilterToShowRest: (state, action) => {
            state.getFilterToShowRest = action.payload
        },
        saveSelectedCountryId: (state, action) => {
            state.getSelectedCountryId = action.payload
        }


    },
});

export const { saveSelectedCountryId, saveIsFilterToShowDefault, saveIsFilterToShowRest,
    saveIsFilterSearch, saveIsTableLoader, saveIsHistoryTableLoader,
    saveIsShowDownloadBookMark, saveIsClickedSubMenuEvent,
    saveIsAddToBookMarkData, saveIsHistoryDataCall, saveIsTableDataCall,
    saveIsTableData, saveIsReportNameId, saveIsHistoryTableData,
    saveIsFilterData, saveIsCloseSubmenu, saveIsReportingRender,
    saveReportingObj, saveIsDispatch, saveIsNetwork, saveIsClaim,
    saveIsSubMenu, saveIsMenuData } = MenuItemSlice.actions;

export default MenuItemSlice.reducer;