import React, { Suspense, useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import PrivateRoute from "../utils/PrivateRoute";
import AccessDenied from "../pages/AccessDenied/AccessDenied";
import { render } from "react-dom";
import { useSelector } from "react-redux";


// const RedirectHandler = React.lazy(() => import("../utils/RedirectHandler"));
// const Dashboard = React.lazy(() => import("../pages/Dashboard/Dashboard"));
// const AccountDetails = React.lazy(() =>
//   import("../pages/AccountDetails/AccountDetails")
// );

const Login = React.lazy(() => import("../pages/Login/Login"));
const SignIn = React.lazy(() => import("../pages/Dashboard/SignIn"));

const ServicerNavBar = React.lazy(() =>
  import("../components/ServicerNavBar/ServicerNavBar")
);
const NotFound = React.lazy(() => import("../pages/NotFound"));
const MyTickets = React.lazy(() => import("../pages/MHIT/MyTickets"))
const MyJobs = React.lazy(() => import("../pages/MHIT/myJob"))
const Home = React.lazy(() => import("../pages/MHIT/Home"))




const SuspenseWrapper = ({ children }) => {
  const getFileClaimWithoutJobFromRedux = useSelector((state) => {
    return Number(state?.Login?.fileClaimWithoutJob) || 0;

  })
  return <Suspense fallback={<Loader></Loader>}>{children}</Suspense>;
};

const Loader = () => {
  return (
    <CircularProgress
      size={50}
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        // marginTop: `${-50 / 2}px`,
        marginTop: "200px",
        marginLeft: `${-50 / 2}px`,
      }}
    />
  );
};

const role = localStorage.getItem("roleType");

export const useDynamicRoutes = () => {

  const getFileClaimWithoutJobFromRedux = useSelector((state) => Number(state?.Login?.fileClaimWithoutJob) || 0);
  return [
    {
      path: "/",
      element: (
        <SuspenseWrapper>
          <SignIn />
        </SuspenseWrapper>
      ),
    },
    {
      path: "/login",
      element: (
        <SuspenseWrapper>
          <Login />
        </SuspenseWrapper>
      ),
    },
    {
      path: "/AccessDenied",
      element: (
        <PrivateRoute
          acessdenied={
            <SuspenseWrapper>
              <AccessDenied />
            </SuspenseWrapper>
          }
        ></PrivateRoute>
      ),

      // element: <SuspenseWrapper><AccessDenied /></SuspenseWrapper>
    },
    {
      path: "/auth/redirect",
      element: (
        <SuspenseWrapper>
          <SignIn />
        </SuspenseWrapper>
      ),
    },
    {
      path: "/myTickets",
      element: (
        <PrivateRoute
          normalComponent={
            <SuspenseWrapper>
              <MyTickets />
            </SuspenseWrapper>
          }
        ></PrivateRoute>
      ),
    },
    ,
    {
      path: "/myJobs",
      element: (
        <PrivateRoute
          normalComponent={
            <SuspenseWrapper>
              <MyJobs />
            </SuspenseWrapper>
          }
        ></PrivateRoute>
      ),
    },
    ,
    {
      path: "/home",
      element: (
        <PrivateRoute
          normalComponent={
            <SuspenseWrapper>
              <Home />
            </SuspenseWrapper>
          }
        ></PrivateRoute>
      ),
    },
    {
      path: "*",
      element: (
        <PrivateRoute
          notfound={
            <SuspenseWrapper>
              <NotFound />
            </SuspenseWrapper>
          }
        ></PrivateRoute>
      ),
      // element: <SuspenseWrapper><NotFound /></SuspenseWrapper>,
    },
  ]

}
