import JobsIcon from "../../assets/mhit-icons/jobs-icon.svg";
import DashboardIcon from "../../assets/mhit-icons/dashboard-icon.svg";
import TasksIcon from "../../assets/mhit-icons/tasks-icon.svg";
// import { useSelector } from "react-redux";
import { useState } from "react";

  const MenuItems=()=>{
    const [roleType,setRoleType]=useState(localStorage.getItem('roleType'))
    return[
      {
        key: "1",
        title: "Home",
        isActive: "false",
        path: "/home",
        Icon: [DashboardIcon],
        show: true
      },
      {
        key: "2",
        title: "My Tickets",
        isActive: "false",
        path: "/myTickets",
        Icon: [TasksIcon],
        show: roleType === 'admin' ? false : true,
      },
      {
        key: "3",
        title: "My Jobs",
        isActive: "false",
        path: "/myJobs",
        Icon: [JobsIcon],
        show: roleType === 'admin' ? true : false,
      }
    ]
  }
export default MenuItems;