import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AddSharpIconB from "../../assets/NavBar/Active/icon-plus (1).svg";
import AddSharpIconW from "../../assets/NavBar/onHover/icon-plusW.svg";

import SearchIconB from "../../assets/NavBar/Active/SeachIconB.svg";
import SearchIconW from "../../assets/NavBar/onHover/SearchW.svg";

import RateCardB from "../../assets/NavBar/Active/file-upload 1 (1).svg";
import RateCardW from "../../assets/NavBar/onHover/RateCardW.svg";

import RateTypeW from "../../assets/NavBar/onHover/RateTypeW.svg";
import RateTypeB from "../../assets/NavBar/Active/RateTypeIconB.svg";

import IndustryW from "../../assets/NavBar/onHover/Industryw.svg";
import IndustryB from "../../assets/NavBar/Active/BuildingB.svg";

import UploadRankingW from "../../assets/NavBar/onHover/file-upload W.svg";
import UploadRankingB from "../../assets/NavBar/Active/file-uploadB.svg";

import DispatchW from "../../assets/NavBar/onHover/dispatchW.svg";
import DispatchB from "../../assets/NavBar/Active/dispatchB.svg";

import RankingParametersW from "../../assets/NavBar/onHover/ranking (1) 2W.svg";
import RankingParametersB from "../../assets/NavBar/Active/rankingB.svg";

import ServiceOrderW from "../../assets/NavBar/onHover/Service OrderW.svg";
import ServiceOrderB from "../../assets/NavBar/Active/Service OrderB.svg";

import FileClaimIconB from "../../assets/NavBar/claimsIcon-blue.svg"
import FileClaimIconW from "../../assets/NavBar/claimsIocn-white.svg"


export const menuItems = [
    {
        title: 'Network Management',
        icon: <KeyboardArrowDownIcon />,
        subMenu: [{
            title: 'Add New Servicer',
            path: '/NetworkManagement/AddNewServicer/AccountDetails',
            icon: [<img src={AddSharpIconB} alt="icon" />, <img src={AddSharpIconW} alt="icon" />],
        },
        {
            title: 'Search Servicer',
            icon: [<img src={SearchIconB} alt="icon" />,
            <img src={SearchIconW} alt="icon" />],
            path: '/NetworkManagement/Servicer'
        }]
    },

    {
        title: 'Service Delivery',
        icon: <KeyboardArrowDownIcon />,
        subMenu: [
            {
                title: 'Ranking Parameters',
                icon: [<img src={RankingParametersB} alt="icon" />,
                <img src={RankingParametersW} alt="icon" />],
                path: '/DisplayRanking'

            },
            {
                title: 'Dispatch Simulator',
                icon: [<img src={DispatchB} alt="icon" />,
                <img src={DispatchW} alt="icon" />],
                path: '/DispatchSimulator'
            },
            {
                title: 'Service Order',
                icon: [<img src={ServiceOrderB} alt="icon" />,
                <img src={ServiceOrderW} alt="icon" />],
                path: '/ServiceOrder'
            },

            {
                title: 'Upload Ranking File',
                icon: [<img src={UploadRankingB} alt="icon" />,
                <img src={UploadRankingW} alt="icon" />],
                path: '/UploadRanking'
            }
        ]
    },
    {
        title: 'Onboarding',
        icon: <KeyboardArrowDownIcon />,
        subMenu: []
    },
    {
        title: 'Claims',
        icon: <KeyboardArrowDownIcon />,
        subMenu: [
            {
                title: 'File Claim',
                icon: [<img src={FileClaimIconB} alt="icon" />,
                <img src={FileClaimIconW} alt="icon" />],
                path: `${process.env.REACT_APP_CLAIMS_URL}/dashboard?access_token=${localStorage?.getItem("keyCloakLoginToken")}`

            }
        ]
    },

    {
        title: 'Application Admin',
        icon: <KeyboardArrowDownIcon />,
        subMenu: [{
            title: 'Industry',
            icon: [<img src={IndustryB} alt="icon" />,
            <img src={IndustryW} alt="icon" />],

            path: '/ApplicationAdmin/Industries',
            hover: false,
        },
        {
            title: 'Rate Type',

            icon: [<img src={RateTypeB} alt="icon" />,
            <img src={RateTypeW} alt="icon" />],

            path: '/ApplicationAdmin/AddNewRateType'
        },
        {
            title: 'Rate Card',
            icon: [<img src={RateCardB} alt="icon" />,
            <img src={RateCardW} alt="icon" />],
            path: '/ApplicationAdmin/AvailableRateCard'
        },

        ]

    }
]


